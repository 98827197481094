import { observer } from 'mobx-react-lite'
import { ReactComponent as SuccessIcon } from './Assets/Congrat.svg'
import { Stack, Typography } from '@mui/material'
import { useTranslate, T } from 'GlobalStores/Cores/Translator/useTranslate'

const SuccessChangePhoneNumber = observer(props => {
    const translate = useTranslate()
    return (
        <Stack height={'100vh'} sx={{ alignItems: 'center' }} padding={'24px 16px'}>
            <Stack
                sx={{
                    background: '#FFFFFF',
                    borderRadius: '12px',
                    alignItems: 'center',
                    padding: '43px 23px',
                    maxWidth: '600px',
                    gap: '32px',
                    '@media screen and (min-width: 600px)': {
                        gap: '32px',
                    }
                }}
            >
                <SuccessIcon />
                <Typography 
                    align="center"
                    sx={{
                        fontSize: '32px',
                        fontWeight: 700,
                        lineHeight: '38px',
                        color: '#003B6F',
                        padding: '0px 20px'
                    }}
                >
                    <T>desc_change_phone_number</T>
                </Typography>
            </Stack>
        </Stack>
    )
  })

export default SuccessChangePhoneNumber