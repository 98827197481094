import { Dialog, DialogContent, Stack, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { Suspense, useEffect, useMemo, useState } from 'react'
import { lazy } from 'react'
import pMinDelay from 'p-min-delay'
import storeCheckout from 'GlobalStores/Checkout/StoreCheckout'
import { runInAction, toJS } from 'mobx'
import { storeElement } from 'GlobalStores/StoreElement'
import storeCountry from 'GlobalStores/Cores/County/StoreCountry'
import styled from '@emotion/styled'
import DuplicatedOrder from 'Components/Elements/DuplicatedOrder/DuplicatedOrder'
import { FormatAPIs } from 'Services/FeelGreat/FormatAPIs'
import { useLocation, useNavigate } from 'react-router-dom'
import storeAuth from 'GlobalStores/User/StoreAuth'
import storeUser from 'GlobalStores/User/StoreUser'
import storeFormat from 'GlobalStores/Format/StoreFormat'
import { CreditCardHelper } from 'Helpers/CreditCardHelper'
import { get } from 'lodash'
import { T } from 'GlobalStores/Cores/Translator/useTranslate'
import ModalProcessPayments from 'Components/Elements/ModalProcessPayments/ModalProcessPayments'
import StorePaymentStep from 'GlobalStores/Cores/Elements/StorePaymentStep'
import StoreProducts from 'GlobalStores/StoreProducts'
import trackingEvents from 'Services/Tracker/TrackingEvents'
import storeReferral from 'GlobalStores/Referral/StoreReferral'

const PreSummaryBoxLazyLoad = lazy(() => pMinDelay(import('../../Elements/Checkout/ShippingAddressForm/Country/Thailand/ThailandPreSummaryBox'), 0))
const ShippingAddressBoxLazyLoad = lazy(() => pMinDelay(import('../../Elements/Checkout/ShippingAddressForm/Country/Thailand/ThailandShippingAddress'), 0))
const CreateAccountWithShippingLazyLoad = lazy(() =>
    pMinDelay(import('../../Elements/Checkout/ShippingAddressForm/Country/Thailand/ThailandCreateAccountWithShipping'), 0)
)
const PaymentBoxLazyLoad = lazy(() => pMinDelay(import('../../Elements/Checkout/Payment'), 0))
const PaymentReviewLazyLoad = lazy(() => pMinDelay(import('Components/Elements/Checkout/PaymentReview'), 0))

const ThailandCheckoutTheme = observer(({ step }) => {
    const navigate = useNavigate()
    const location = useLocation()
    const qs = useMemo(() => new URLSearchParams(window.location.search), [])

    const [, setSectionReady] = useState(false)

    const [openDuplicatedModal, setOpenDuplicatedModal] = useState(false)
    const [duplicatedData, setDuplicatedData] = useState({
        paymentId: '',
        dateAndTime: '',
        totalPrice: '',
        currency: '',
        is_duplicate: false
    })

    const clearAndResetStates = () => {
        runInAction(() => {
            storeCheckout.checkoutErrors = []
            storeElement.showCardFrontDrop = false
            storeCheckout.checkoutLoading = false
            storeCheckout.paymentErrors = []
            storeCheckout.paymentLoading = false
        })
    }

    const closeDuplicateModal = () => {
        setOpenDuplicatedModal(false)
        runInAction(() => {
            storeElement.showCardFrontDrop = false
        })
    }

    const confirmPlaceOrder = async () => {
        setOpenDuplicatedModal(false)
        runInAction(() => {
            storeCheckout.paymentErrors = []
            storeElement.showCardFrontDrop = true
        })
        await SubmitPayment()
    }

    const checkDuplicateOrder = async () => {
        try {
            runInAction(() => {
                storeCheckout.paymentErrors = []
                storeElement.showCardFrontDrop = true
            })
            const checkDuplicateOrder = await FormatAPIs.checkDuplicateOrder({
                countryCode3Alpha: storeCountry.Country3(),
                uuid: storeCheckout.GetUuid() || qs.get('uuid')
            })

            if (checkDuplicateOrder.data.is_duplicate === true) {
                setDuplicatedData({
                    paymentId: checkDuplicateOrder.data.payments_id,
                    dateAndTime: checkDuplicateOrder.data.date_time,
                    totalPrice: checkDuplicateOrder.data.total_price,
                    is_duplicate: true,
                    currency: checkDuplicateOrder.data.currency || 'THB'
                })
                setOpenDuplicatedModal(true)
                return
            } else {
                await SubmitPayment()
            }
        } catch (e) {
            console.log('error', e)
            closeDuplicateModal()
        }
    }

    const afterPassed = () => {
        setTimeout(() => {
            runInAction(() => {
                storeCheckout.checkoutLoading = false
                storeElement.showCardFrontDrop = true
            })
            navigate(`/checkout/review?uuid=${new URLSearchParams(location.search).get('uuid')}`)
        }, 1000)
    }

    const GetCardValue = key => {
        const card = storeCheckout.creditCard.find(item => item.label === key)
        if (card) {
            return card.value
        }
        return ''
    }

    const beginCheckoutGA4 = () => {
        let items = []
        const products = toJS(StoreProducts.GetProducts())
        const getCart = toJS(storeCheckout.GetCart())
        const purchaseOption = Object.keys(getCart)
        const productFromCart = toJS(storeFormat.GetFormatData('formatProducts'))

        productFromCart.map(i => {
            const foundFlavor = Object.keys(getCart[i.type])
            const foundProductByPurchaseOptions = purchaseOption.map(option => {
                return products[foundFlavor][option]
            })
            const foundProductByItemCode = foundProductByPurchaseOptions.find(product => product.item_code === i.item_code)

            items.push({
                item_code: i.item_code,
                item_name: foundProductByItemCode.flavor.description.english,
                flavor: foundProductByItemCode.flavor.text.english,
                price: storeCheckout.HasDiscount() ? i.price.member : i.price.retail,
                totalPrice: Number(storeCheckout.HasDiscount() ? i.price.member * i.buyQty : i.price.retail * i.buyQty),
                pv: foundProductByItemCode.pv,
                qty: i.buyQty,
                type: i.type
            })
        })

        const totalPrice = items.map(item => item.totalPrice).reduce((a, b) => a + b, 0)

        return {
            items: items,
            value: totalPrice
        }
    }

    const onPlaceOrder = async () => {
        try {
            storeCheckout.checkoutLoading = false

            runInAction(() => {
                storeCheckout.checkoutErrors = []
                storeElement.showCardFrontDrop = true
                storeCheckout.checkoutLoading = true
            })

            if (step === 'payment') {
                const responseGA4 = beginCheckoutGA4()
                const { items, value } = responseGA4

                trackingEvents.BeginCheckout(trackingEvents.EVENT_TYPE.ADD_TO_CART.toLowerCase(), items, value, storeReferral.GetReferral())

                if (storeCheckout.CheckOutInstance().GetPaymentMethod() === 'creditcard') {
                    const creditCard = {
                        countryCode: storeCountry.Country2(),
                        creditCard: {
                            creditCardNumber: GetCardValue('card_number'),
                            payer: GetCardValue('name_on_card'),
                            creditCardExpires: GetCardValue('exp_date'),
                            creditCardSecurityCode: GetCardValue('cvv')
                        }
                    }
                    const cardValidate = CreditCardHelper.cardValidate(creditCard)
                    if (!cardValidate.success) {
                        let cardErrors = []
                        cardValidate.error_messages.map(item => {
                            cardErrors.push(item)
                            return item
                        })
                        runInAction(() => {
                            storeElement.showCardFrontDrop = false
                            storeCheckout.checkoutErrors = cardErrors
                            storeCheckout.checkoutLoading = false
                        })
                        // console.log('cardErrors', cardErrors)
                        return
                    }
                }

                if (!storeAuth.GetToken()) {
                    await storeAuth.Login({ unicityID: storeUser.baId, password: storeUser.password })
                }

                if (storeCheckout.useBillingAddressSameAsShipping === false) {
                    let data = {
                        uuid: new URLSearchParams(location.search).get('uuid'),
                        shipment_options: 'delivery'
                    }
                    if (storeCheckout.GetBillingAddressForm()) {
                        storeCheckout.GetBillingAddressForm().map(item => {
                            if (item.key !== 'country') {
                                let key = item.key.toLowerCase()
                                if (key === 'zip_code') {
                                    key = 'zip'
                                }
                                if (key === 'street_address') {
                                    key = 'address'
                                }
                                data[`ship_address_${key}`] = item.value
                            }

                            return item
                        })

                        data['ship_address_fullname'] = storeFormat.GetDetailPage().fullname
                        data['customer_main_address'] = storeUser.CustomerData().Country()
                    }

                    const updateResponse = await FormatAPIs.putHotFormatV2(data, storeCountry.Country3(), true)
                    if (updateResponse.data.success) {
                        afterPassed()
                    } else {
                        runInAction(() => {
                            storeElement.showCardFrontDrop = false
                            storeCheckout.checkoutErrors = updateResponse.data.Message
                            storeCheckout.checkoutLoading = false
                        })
                    }
                } else {
                    let data = {
                        uuid: new URLSearchParams(location.search).get('uuid'),
                        shipment_options: 'delivery'
                    }

                    const updateResponse = await FormatAPIs.putHotFormatV2(data, storeCountry.Country3(), true)
                    if (updateResponse.data.success) {
                        afterPassed()
                    } else {
                        runInAction(() => {
                            storeElement.showCardFrontDrop = false
                            storeCheckout.checkoutErrors = updateResponse.data.Message
                            storeCheckout.checkoutLoading = false
                        })
                    }
                }
            } else {
                await checkDuplicateOrder()
            }
        } catch (error) {
            console.log('error', error)
            clearAndResetStates()
        }
    }

    const SubmitPayment = async () => {
        try {
            const responseGA4 = beginCheckoutGA4()
            const { items } = responseGA4
            if (storeCheckout.GetPaymentMethod() === 'qr_payment') {
                runInAction(() => {
                    storeCheckout.paymentLoading = false
                    storeCheckout.paymentErrors = []
                    storeElement.showCardFrontDrop = true
                })
                const response = await storeCheckout.CheckOutInstance().SubmitPayment({
                    uuid: storeCheckout.GetUuid(),
                    detailPage: storeFormat.GetDetailPage(),
                    products: items,
                    type: 'qr'
                })

                if (response.success) {
                    // updatePdpa()
                    navigate(`/qr/payment?ref_id=${response.payment_ref}`)
                    runInAction(() => {
                        storeCheckout.paymentLoading = false
                    })
                } else {
                    clearAndResetStates()
                }

                return
            } else {
                Object.keys(storeCheckout.CheckOutInstance().Instance().PaymentStep).map(key => {
                    if (storeCheckout.CheckOutInstance().Instance().PaymentStep[key] !== -2) {
                        storeCheckout.CheckOutInstance().Instance().PaymentStep[key] = 0
                    }
                })

                runInAction(() => {
                    StorePaymentStep.SetPaymentSteps(storeCheckout.CheckOutInstance().Instance().PaymentStep)
                    StorePaymentStep.errors = []
                    storeCheckout.paymentLoading = true
                    storeCheckout.paymentErrors = []
                    storeElement.showCardFrontDrop = true
                })

                const creditCard = {
                    countryCode: 'TH',
                    creditCard: {
                        creditCardNumber: GetCardValue('card_number'),
                        payer: GetCardValue('name_on_card'),
                        creditCardExpires: GetCardValue('exp_date'),
                        creditCardSecurityCode: GetCardValue('cvv')
                    }
                }

                const cardValidate = CreditCardHelper.cardValidate(creditCard)

                if (cardValidate.success) {
                    if (get(storeFormat.GetDetailPage(), 'type') !== 'enroll' && !storeAuth.GetToken()) {
                        await storeAuth.Login({ unicityID: storeUser.baId, password: storeUser.password })
                    }
                } else {
                    let cardErrors = []
                    cardValidate.error_messages.map(item => {
                        cardErrors.push(item)
                        return item
                    })

                    clearAndResetStates()

                    runInAction(() => {
                        storeCheckout.paymentErrors = [...cardErrors, `UUID : ${storeCheckout.GetUuid()}`]
                        clearAndResetStates()
                    })
                    return
                }

                const creditCardData = storeCheckout.CheckOutInstance().CreditCardEncrypted(creditCard.creditCard)
                console.log('check subscribe', storeCheckout.CheckSubscribeInCart())
                if (storeCheckout.CheckSubscribeInCart()) {
                    runInAction(() => {
                        StorePaymentStep.SetPaymentStep('autoship', 0)
                    })
                } else {
                    runInAction(() => {
                        StorePaymentStep.SetPaymentStep('autoship', -2)
                    })
                }

                const response = await storeCheckout.CheckOutInstance().SubmitPayment({
                    uuid: storeCheckout.GetUuid(),
                    detailPage: storeFormat.GetDetailPage(),
                    CreditCardEncrypted: creditCardData,
                    products: items
                })

                if (response.success) {
                    runInAction(() => {
                        runInAction(() => (storeElement.showCardFrontDrop = true))
                        storeCheckout.paymentLoading = false
                    })

                    setTimeout(() => {
                        navigate(`/payment-response/success?payment_id=${response.payment_id}${response.order_type ? '&type=enroll' : ''}`)
                    }, 500)
                } else {
                    console.log('error payment', StorePaymentStep.PaymentSteps)
                    if (StorePaymentStep.PaymentSteps) {
                        if (StorePaymentStep.GetPaymentSteps().payment === -1) {
                            StorePaymentStep.SetPaymentStep('createOrder', -1)

                            if (StorePaymentStep.GetPaymentSteps().autoship > -2) {
                                StorePaymentStep.SetPaymentStep('autoship', -1)
                            }

                            StorePaymentStep.SetPaymentStep('updateLog', -1)
                        } else if (StorePaymentStep.GetPaymentSteps().createOrder === -1) {
                            if (StorePaymentStep.GetPaymentSteps().autoship > -2) {
                                StorePaymentStep.SetPaymentStep('autoship', -1)
                            }
                            StorePaymentStep.PaymentSteps.updateLog = -1
                        } else if (StorePaymentStep.GetPaymentSteps().autoship === -1) {
                            StorePaymentStep.SetPaymentStep('updateLog', -1)
                        }
                    }
                    runInAction(() => {
                        storeCheckout.checkoutLoading = false
                    })
                    StorePaymentStep.SetErrors(storeCheckout.GetPaymentErrors())
                }
            }
        } catch (e) {
            console.log('error', e)
        }
    }

    const ThemeSections = step => {
        switch (step) {
            case 'create-account':
                return <CreateAccountWithShippingLazyLoad sectionReady={setSectionReady} />
            case 'payment':
                return <PaymentBoxLazyLoad onPlaceOrder={onPlaceOrder} />
            case 'review':
                return <PaymentReviewLazyLoad />
            default:
                return <ShippingAddressBoxLazyLoad sectionReady={setSectionReady} />
        }
    }

    useEffect(() => {
        clearAndResetStates()
    }, [])

    return (
        <>
            <Suspense fallback={<div></div>}>
                <Stack minHeight={'300px'}>{ThemeSections(step)}</Stack>
            </Suspense>
            <Stack>
                <Suspense fallback={<div></div>}>
                    <PreSummaryBoxLazyLoad step={step} edit={true} isReady={storeCheckout.isReadyToPlaceOrder} onPlaceOrder={onPlaceOrder} />
                    {step === 'review' && (
                        <DialogDuplicatedStyled open={openDuplicatedModal}>
                            <DialogContent
                                sx={{
                                    padding: {
                                        xs: '32px 24px',
                                        md: '64px 93px'
                                    }
                                }}>
                                <DuplicatedOrder confirmPlaceOrder={confirmPlaceOrder} setOpenModal={closeDuplicateModal} {...duplicatedData} />
                            </DialogContent>
                        </DialogDuplicatedStyled>
                    )}
                    <ModalProcessPayments isOpen={storeCheckout.paymentLoading} onClose={() => runInAction(() => (storeCheckout.paymentLoading = false))} />
                </Suspense>
            </Stack>
        </>
    )
})

const DialogDuplicatedStyled = styled(Dialog)`
    .MuiDialog-paper {
        border-radius: 12px;
        box-shadow: none;
        @media screen and (min-width: 768px) {
            width: 100%;
            max-width: 650px;
        }
    }
`

export default ThailandCheckoutTheme
