import storeUser from 'GlobalStores/User/StoreUser'
import httpHelper from 'Services/HttpHelper'
import axios from 'axios'
import { get, last } from 'lodash'

const editReferralCode = (token, customWordReferral) => {
    const customerHref = last(get(storeUser, 'userData.href', '').split('/'))
    const url = `${httpHelper.Proxy({
        originalUrl: `https://member-calls2.unicity.com/validate/referralCode/save`
    })}`

    const data = {
        referralCode: customWordReferral,
        customerHref: customerHref
    }

    return httpHelper.Post({
        url: url,
        data,
        config: {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }
    })
}

const validateReferralCode = customWordReferral => {
    const url = `${httpHelper.Proxy({
        originalUrl: `https://member-calls2.unicity.com/validate/referralCode/validate?referralCode=${customWordReferral}`
    })}`

    return axios.get(url)
}

export const ReferralLinkApis = {
    editReferralCode,
    validateReferralCode
}
