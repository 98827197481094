import DefaultInput from 'Components/Elements/Invite/ReferralLinks/ChangeEnrollModal/DefaultInput'
import { Button, Card, CircularProgress, InputAdornment, Stack, styled, TextField, Typography } from '@mui/material'
import storeCountry from 'GlobalStores/Cores/County/StoreCountry'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import DataJson from './Data/Data.json'
import ModalLogin from './ModalLogin'
import { validatePhoneNumber } from './APIs/APIs'
import { T, useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import { ReactComponent as LockIcon } from './Assets/Lock.svg'
import { useNavigate } from 'react-router-dom'

const ChangeMobilePhone = observer(props => {
    const [data, setData] = useState({})
    const [openModal, setOpenModal] = useState(false)
    const [phoneNumber, setPhoneNumber] = useState('')
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)

    const translate = useTranslate()
    const navigate = useNavigate()

    useEffect(() => {
        const data = DataJson.filter(item => item.code === storeCountry.Country2())
        setData(data[0])
    }, [])

    const CheckValidatePhoneNumber = async () => {
        setLoading(true)
        setError('')

        try {
            const dataPhoneNumber = phoneNumber.charAt(0) === '0' ? phoneNumber.slice(1) : phoneNumber
            const concatPhoneWithDialCode = `${data.dial_code}${dataPhoneNumber}`.slice(1)

            const responseValidatePhoneNumber = await validatePhoneNumber(concatPhoneWithDialCode.replace(/-/g, ''))

            if (responseValidatePhoneNumber.data.success === false) {
                setError(responseValidatePhoneNumber.data.message)
                setLoading(false)
                return
            }

            setOpenModal(true)
            setLoading(false)
        } catch (err) {
            console.log('error', err)
            setLoading(false)
        }
    }

    const onChange = e => {
        const { value } = e.target
        const regex = /^[\d-]+$/
        if (!regex.test(value) && value.length > 0) {
            return
        }

        if (value.length > 12 && value.charAt(0) === '0') {
            return
        } else if (value.length > 11 && value.charAt(0) !== '0') {
            return
        }

        setError('')
        setPhoneNumber(formatDigitPhone(value))
    }

    const formatDigitPhone = phoneNumber => {
        const cleaned = phoneNumber.replace(/\D/g, '')

        const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/)

        if (match) {
            return [match[1], match[2], match[3]].filter(Boolean).join('-')
        }

        return phoneNumber
    }

    return (
        <Stack padding={'24px 16px 200px 16px'} alignItems={'center'}>
            <Container>
                <Stack gap={'40px'}>
                    <Typography sx={{ fontSize: '24px', fontWeight: 700, color: '#003B6F' }}>
                        <T>change_phone_number</T>
                    </Typography>

                    <Stack width={'100%'} flexDirection={'row'} justifyContent={'space-between'} gap={'8px'}>
                        <TextFieldStyled
                            disabled
                            InputProps={{
                                startAdornment: Object.keys(data).length > 0 && (
                                    <InputAdornment sx={{ marginRight: '5px' }} position="start">
                                        <Flag flag={data.code} type />
                                    </InputAdornment>
                                ),
                                endAdornment: Object.keys(data).length > 0 && (
                                    <InputAdornment sx={{ width: '30px' }} position="end">
                                        <LockIcon />
                                    </InputAdornment>
                                )
                            }}
                            value={data.dial_code}
                        />
                        <DefaultInputStyled
                            placeholder={translate('new_phone_number')}
                            value={phoneNumber}
                            onChange={onChange}
                            onFocus={e => e.target.addEventListener('wheel', e => e.preventDefault(), { passive: false })}
                            type={'tel'}
                            inputProps={{
                                inputMode: 'numeric',
                                pattern: '[0-9]*',
                                autoComplete: 'none'
                            }}
                        />
                    </Stack>
                </Stack>

                {error && (
                    <Stack sx={{ background: '#FFE7EB', borderRadius: '8px', padding: '12px 16px', marginTop: '28px' }}>
                        <Typography variant="label" sx={{ color: '#FE5D7C', textAlign: 'center', fontSize: '14px', fontWeight: 300 }}>
                            <T>{error}</T>
                        </Typography>
                    </Stack>
                )}

                <Stack flexDirection={'row'} justifyContent={'space-between'} mt={'28px'}>
                    <CancelButtonStyled disableRipple onClick={() => navigate('/user/settings')}>
                        <T>cancel</T>
                    </CancelButtonStyled>

                    <ConfirmButtonStyled disableRipple disabled={phoneNumber.length < 11 || loading === true} onClick={CheckValidatePhoneNumber}>
                        {loading ? <CircularProgress size={24} color={'inherit'} /> : <T>confirm_change</T>}
                    </ConfirmButtonStyled>
                </Stack>

                <ModalLogin openLoginModal={openModal} setOpenLoginModal={setOpenModal} dataPhoneNumber={phoneNumber.replace(/-/g, "")} />
            </Container>
        </Stack>
    )
})

const Flag = observer(props => {
    const { flag } = props
    return (
        <Wrapper>
            <FlagImage src={require(`./Assets/FlagSVG/${flag}.svg`)} />
        </Wrapper>
    )
})

const Container = styled(Card)`
    box-shadow: none;
    border-radius: 12px;
    padding: 40px 16px;
    max-width: 600px;

    @media screen and (min-width: 630px) {
        padding: 40px 55px;
    }
`

const TextFieldStyled = styled(TextField)`
    .MuiOutlinedInput-root {
        width: 110px;
        border-radius: 8px;
        border: 1px solid #ccdeef;
        height: 50px;
        color: #003b6f;
        font-size: 14px;
        font-weight: 400;

        .Mui-disabled {
            -webkit-text-fill-color: #003b6f;
            font-size: 14px;
        }

        @media screen and (min-width: 600px) {
            width: 130px;
        }
    }

    .MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
        -webkit-text-fill-color: #5a8fc3;
    }

    .MuiOutlinedInput-notchedOutline {
        border: none;
    }
`

const DefaultInputStyled = styled(DefaultInput)`
    .MuiInputBase-input {
        width: 193px;
        height: 50px;
        font-size: 16px;
        font-weight: 400;
        color: #003b6f;
        font-family: 'Poppins';
        border-color: #ccdeef;

        &::-webkit-input-placeholder {
            color: #5a8fc3;
            opacity: 1;
        }

        @media screen and (min-width: 430px) {
            width: 100%;
        }

        @media screen and (min-width: 630px) {
            width: 344px;
        }
    }
`

const ConfirmButtonStyled = styled(Button)`
    height: 50px;
    width: 220px;
    background-color: #079ff4;
    color: #ffffff;
    border-radius: 99px;
    font-size: 20px;
    font-weight: 600;
    padding: 10px 20px;
    text-transform: capitalize;
    font-family: 'Poppins';

    &.MuiButton-root.Mui-disabled {
        background-color: #ccdeef;
        color: #ffffff;
    }

    &:hover {
        background-color: #079ff4;
    }

    & .MuiTouchRipple-child {
        background-color: transparent;
    }

    @media screen and (min-width: 430px) {
        padding: 10px 30px;
        width: 240px;
    }
`

const CancelButtonStyled = styled(Button)`
    height: 50px;
    background-color: transparent;
    color: #5a8fc3;
    font-size: 16px;
    font-weight: 300;
    font-family: 'Poppins';
    text-transform: capitalize;
    text-decoration: underline;

    &:hover {
        background-color: transparent;
        text-decoration: underline;
    }

    & .MuiTouchRipple-child {
        background-color: transparent;
    }
`

const Wrapper = styled(`div`)`
    width: 25px;
    display: flex;
`

const FlagImage = styled(`img`)`
    // width: 30px;
    height: 15px;

    &.border-header {
        border-radius: 3px;
    }
`

export default ChangeMobilePhone
