import { Card, CardContent, ThemeProvider, Typography } from '@mui/material'
import { MainTemplate } from 'Components/Template/MainTemplate'
import { observer } from 'mobx-react-lite'
import { default as Success } from './Assets/success.svg'
import { Box } from '@mui/system'
import { useSizeWindow } from 'Helpers/GlobalHelpers'
import { T } from 'GlobalStores/Cores/Translator/useTranslate'
import { get, upperCase } from 'lodash'
import storeCountry from 'GlobalStores/Cores/County/StoreCountry'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import storeFeelGreat from 'GlobalStores/FeelGreat/StoreFeelGreat'
import StoreTranslate from 'GlobalStores/Cores/Translator/StoreTranslate'
import storeFormat from 'GlobalStores/Format/StoreFormat'

const disablePaymentNumber = ['SG', 'TR', 'JO', 'EG']
const disabledOnlyTitlePaymentNumber = ['ZA']
const showTextSendReceiptToEmail = ['ZA']

const ThankyouCard = observer(props => {
    const { width } = useSizeWindow()
    const location = useLocation()
    const navigate = useNavigate()
    let isGCash = new URLSearchParams(location.search).get('method') === 'GCash'

    const isRedirect = () => {
        let redirect = true

        if (props.states.orderNumber.split('-').length > 1) {
            redirect = false
        }

        return redirect
    }
    // console.log('email', (get(storeFeelGreat.contents, 'contact.email', '')))

    const findPurchaseSubscription = () => {
        const items = storeFormat.GetDetailPage()?.shipment_packs
        if (items) {
            const itemsShipmentPacks = JSON.parse(storeFormat.GetDetailPage()?.shipment_packs)
            const foundSubscription = Object.keys(itemsShipmentPacks).some(key => key === 'subscribe')
    
            return foundSubscription
        }
    }

    return (
        <Card>
            <CardContent style={{ padding: width < 600 ? '30px 20px' : '40px 30px 52px' }}>
                <div
                    style={{
                        marginBottom: '30px',
                        height: '24px',
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                    <img src={Success} alt='thankyou' />
                </div>

                {disablePaymentNumber.includes(storeCountry.Country2()) && (
                    <React.Fragment>
                        <Typography
                            variant="h3"
                            sx={{
                                marginBottom: '25px',
                                fontSize: {
                                    xs: '22px',
                                    md: '32px'
                                }
                            }}>
                            <T>thank_you_for_your_order</T>
                        </Typography>
                        <Typography
                            variant="body1"
                            sx={{
                                fontWeight: 400,
                                fontSize: {
                                    xs: '14px'
                                }
                            }}>
                            <T>thank_you_for_your_order_caption</T>
                        </Typography>
                    </React.Fragment>
                )}

                {!disablePaymentNumber.includes(storeCountry.Country2()) && (
                    <React.Fragment>
                        <Typography
                            variant="h3"
                            sx={{
                                marginBottom: width < 600 ? '30px' : '30px',
                                fontSize: {
                                    xs: '22px',
                                    md: '32px'
                                }
                            }}>
                            <T>thank_you_for_your_order</T>
                        </Typography>

                        <Typography
                            variant="body1"
                            sx={{
                                fontWeight: 400,
                                fontSize: {
                                    xs: '14px'
                                },
                                marginBottom: '12px'
                            }}>
                            <T>thank_you_for_your_order_caption</T>
                        </Typography>

                        {isGCash ? (
                            <Box>
                                <Typography
                                    variant="h5"
                                    sx={{
                                        fontFamily: 'Inter, NotoSansThai',
                                        fontStyle: 'normal',
                                        fontWeight: 400,
                                        fontSize: '14px',
                                        color: '#153862',
                                        marginBottom: '12px'
                                    }}>
                                    <T>gcash_payment_response_subtitle</T>
                                </Typography>
                                <Typography
                                    variant="h5"
                                    sx={{
                                        fontFamily: 'Inter, NotoSansThai',
                                        fontStyle: 'normal',
                                        fontWeight: 400,
                                        fontSize: '14px',
                                        color: '#153862'
                                    }}>
                                    <T>gcash_payment_response_subtitle_email</T>
                                </Typography>
                            </Box>
                        ) : (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column'
                                    // marginBottom: width < 600 ? '20px' : '40px'
                                }}>

                                {disabledOnlyTitlePaymentNumber.includes(storeCountry.Country2()) === false ?
                                    <Typography
                                        variant="h5"
                                        sx={{
                                            color: '#003764',
                                            fontSize: '14px',
                                            marginBottom: '12px',
                                            color: '#153862'
                                        }}>
                                        {isRedirect() ? <T>payment_number</T> : <T>order_number</T>}:
                                        <span
                                            style={{
                                                fontFamily: 'Inter, NotoSansThai',
                                                fontStyle: 'normal',
                                                fontWeight: 400,
                                                fontSize: '14px',
                                                color: '#153862'
                                            }}>
                                            {' '}
                                            {/* {props.states.paymentId} */}
                                            {storeCountry.Country2() === 'TH' ? props.states.paymentId : props.states.orderNumber}
                                        </span>
                                    </Typography>
                                : null}
                                
                                {showTextSendReceiptToEmail.includes(storeCountry.Country2()) && (
                                    <Typography
                                        variant="h5"
                                        sx={{
                                            color: '#003764',
                                            fontSize: '14px',
                                            color: '#153862',
                                            fontWeight: 600,
                                            marginBottom: '15px'
                                        }}>
                                        <T>send_your_receipt_payment_to</T>{' '}
                                        <a
                                            href={`mailto: ${get(storeFeelGreat.contents, 'contact.email', '')}`}
                                            style={{
                                                fontFamily: 'Inter',
                                                fontStyle: 'normal',
                                                fontWeight: 600,
                                                fontSize: '14px',
                                                color: '#153862'
                                            }}>
                                            {get(storeFeelGreat.contents, 'contact.email', '')}
                                        </a>
                                    </Typography>
                                )}
                                <Typography
                                    variant="h5"
                                    sx={{
                                        color: '#003764',
                                        fontSize: '14px',
                                        color: '#153862',
                                        marginBottom: '12px'
                                    }}>
                                    <T>your_order_will_be_sent_to</T>:
                                    <span
                                        style={{
                                            fontFamily: 'Inter, NotoSansThai',
                                            fontStyle: 'normal',
                                            fontWeight: 400,
                                            fontSize: '14px',
                                            color: '#153862'
                                        }}>
                                        {' '}
                                        {props.states.email}
                                    </span>
                                </Typography>

                                {findPurchaseSubscription() && (
                                    <Typography
                                        variant="link"
                                        sx={{
                                            color: '#003764',
                                            fontSize: '14px',
                                            color: '#079FF4'
                                        }}
                                        onClick={() => {
                                            navigate(`/user/subscriptions`)
                                        }}>
                                        <T>manage_my_subscription</T>
                                    </Typography>
                                )}
                            </Box>
                        )}
                    </React.Fragment>
                )}
            </CardContent>
        </Card>
    )
})

export default ThankyouCard
