import { get } from 'lodash'
import { autorun, toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import StoreLoader from '../Elements/StoreLoader'
import StoreTranslate from './StoreTranslate'
import storeCountry from '../County/StoreCountry'

export function useTranslate(fallback = 'en') {
    function translate(key, options = { defaultDisplay: null }) {
        const { defaultDisplay } = options || {}

        const fallbackLanguage = `${fallback}.${key}`

        let translated = ''
        let showKey = false
        if (/localhost/.test(window.location.href)) {
            showKey = true
        }

        const currentLanguage = StoreTranslate.CurrentLanguage().toLowerCase() || 'en'

        if (StoreTranslate.Instance().fetchOnce === false) {
            translated = get(StoreTranslate.Dictionaries(), `${currentLanguage}.${key}`, showKey ? key : '')

            if (translated === key) {
                translated = get(StoreTranslate.Dictionaries(), fallbackLanguage, defaultDisplay ? defaultDisplay : showKey ? key : '')
            }
        } else {
            translated = get(StoreTranslate.Instance().lists, `${currentLanguage}.${key}`, showKey ? key : '')

            if (translated === key) {
                translated = get(StoreTranslate.Instance().lists, fallbackLanguage, defaultDisplay ? defaultDisplay : showKey ? key : '')
            }
        }

        return translated !== '' ? translated : key
    }

    return translate
}

export function useTranslateStaticComponents(fallback = 'en') {
    function translateStaticComponents(key, options = { defaultDisplay: null }) {
        const { defaultDisplay } = options || {}
        const currentLanguage = StoreTranslate.CurrentLanguage().toLowerCase() || 'en'
        const fallbackLanguage = `${fallback}.${key}`

        let translated = ''
        let showKey = false
        if (/localhost/.test(window.location.href)) {
            showKey = true
        }

        if (StoreTranslate.Instance().fetchOnce === false) {
            translated = get(StoreTranslate.GetDictionaryStaticComponents(), `${currentLanguage}.${key}`, showKey ? key : '')

            if (translated === key) {
                translated = get(StoreTranslate.GetDictionaryStaticComponents(), fallbackLanguage, defaultDisplay ? defaultDisplay : showKey ? key : '')
            }
        } else {
            translated = get(StoreTranslate.Instance().lists, `${currentLanguage}.${key}`, showKey ? key : '')

            if (translated === key) {
                translated = get(StoreTranslate.Instance().lists, fallbackLanguage, defaultDisplay ? defaultDisplay : showKey ? key : '')
            }
        }

        return translated
    }

    return translateStaticComponents
}

export const T = observer(({ children, defaultDisplay }) => {
    const translate = useTranslate()
    return <>{StoreLoader.dictionary && translate(children, { defaultDisplay })}</>
})

autorun(disposer => {
    if (storeCountry.countryList.length > 0 && storeCountry.Country3() === '' && window.location.pathname.includes('select-country')) {
        StoreTranslate.Instance().getWholeTranslations('AUS')
        disposer.dispose()
    }
})

autorun(disposer => {
    if (storeCountry.countryList.length > 0 && storeCountry.Country3() !== '') {
        StoreTranslate.Instance().getWholeTranslations(storeCountry.Country3())
        disposer.dispose()
    }
})
