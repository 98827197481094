import { Box, Stack, ThemeProvider, Typography, Tab, Tabs, Divider } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import StoreCountry from 'GlobalStores/Cores/County/StoreCountry'
import { MainTemplate } from 'Components/Elements/AppComponent/Template/MainTemplate'
import { autorun } from 'mobx'
import { useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import TabElement from 'Components/Elements/TabElement'
import CollapseSelectCountry from 'Components/Elements/Header/CollapseSelectCountry'
import storeReCaptcha from 'GlobalStores/StoreReCaptcha'
import { get, lowerCase, startsWith } from 'lodash'
import { ServerEnv } from 'Configs/ServerEnv'
import CountryItem from 'Components/Elements/Header/CountryItem'
import Mustache from 'mustache'
import StoreTranslate from 'GlobalStores/Cores/Translator/StoreTranslate'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    height: {
        xs: '100%',
        sm: '80%'
    },
    width: {
        xs: '100%',
        sm: '90%',
        md: '85%',
        xl: '76%'
    },
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: { xs: '0px', sm: '8px' },
    border: 'none',

    '&:focus': {
        outline: 'none'
    },
    overflowY: 'scroll',
    '::-webkit-scrollbar': {
        display: 'none'
    }
}

const SelectCountryPage = observer(() => {
    const [value, setValue] = useState(0)
    const [width, setWidth] = useState(window.innerWidth)
    const [isMobile, setIsMobile] = useState(false)

    const params = useParams()

    const translate = useTranslate()

    useEffect(() => {
        window.addEventListener('resize', updateDimensions)
        autorun(disposer => {
            if (StoreCountry.countryList.length > 0) {
                getCountriesData()
                disposer.dispose()
            }
        })
        StoreTranslate.SetCurrentLanguage('en')
        return () => window.removeEventListener('resize', updateDimensions)
    }, [])

    useEffect(() => {
        if (width < 900) {
            setIsMobile(true)
        }
        if (width >= 900) {
            if (value === null) {
                setValue(0)
            }
            setIsMobile(false)
        }
    }, [width, value])

    const getCountriesData = async () => {
        try {
            await StoreCountry.RawSupportedMarketData2()
        } catch (error) {
            console.log(error)
        }
    }

    const updateDimensions = () => {
        setWidth(window.innerWidth)
    }

    const handleChangeTab = (event, index) => {
        setValue(index)
    }

    const a11yProps = index => {
        return {
            id: `tab-${index}`,
            'aria-controls': `tabpanel-${index}`
        }
    }

    const handleClickChangeLanguage = (country, language, defaultLang = '') => {
        storeReCaptcha.setCurrentLanguage(lowerCase(country?.country), defaultLang || language)
        // if (shortCountry === shortCurrentCountry) {
        //   const url = new URL(window.location.href)
        //   url.searchParams.set('lang', (defaultLang || language || '').toLowerCase())
        //   window.history.pushState({}, '', url.toString())
        //   StoreTranslate.SetCurrentLanguage(upperCase(defaultLang || language))

        //   // * switch city option list language
        //   if (shortCurrentCountry === 'twn') {
        //     storeCheckout.CheckOutInstance().Instance().setCityOptionList(true)
        //   }

        //   return
        // }

        const url = get(country, `${ServerEnv.ENV ? 'live' : 'test'}`, '')

        if (!url) {
            return
        }

        const isExternal = startsWith(url, 'https')
        const countryCode = get(country, 'short', '')

        let buildPath = ''
        const paths = window.location.pathname.split('/')
        if (/^\/feature/.test(window.location.pathname)) {
            buildPath = `/${paths[1]}/${paths[2]}/${paths[3]}`
        } else if (/^\/epic/.test(window.location.pathname)) {
            buildPath = `/${paths[1]}/${paths[2]}/${paths[3]}/${paths[4]}/${paths[5]}`
        } else {
            buildPath = ``
        }

        const domain = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '')
        let resultUrl = Mustache.render(url, {
            domain: domain,
            country: countryCode.toLowerCase(),
            language: isExternal ? (defaultLang || language).toLowerCase() : `?lang=${(defaultLang || language).toLowerCase()}`
        })

        if (params.referralId) {
            const baId = params.referralId
            if (/\?/.test(resultUrl)) {
                resultUrl = resultUrl.split('?')
                resultUrl = `${resultUrl[0]}/${baId}?${resultUrl[1]}`
            } else {
                if (/home/.test(resultUrl)) {
                    resultUrl = `${resultUrl.replace('home', 'c')}/${baId}`
                } else {
                    resultUrl = `${url}/${baId}`
                }
            }
        }
        let newUrl = resultUrl
        if (resultUrl.includes(domain)) {
            newUrl = buildPath + resultUrl.replace(domain, '')
        }
        window.open(newUrl, isExternal ? '_blank' : '_self')
    }

    return (
        <ThemeProvider theme={MainTemplate}>
            <Box
                sx={{
                    height: '100%',
                    backgroundColor: '#003B6F',
                    position: 'absolute',
                    top: 0,
                    width: '100%',
                    zIndex: 9999
                }}>
                <Box sx={style}>
                    <Stack
                        sx={{
                            position: 'relative',
                            padding: '50px 30px',
                            overflowY: 'scroll',
                            '::-webkit-scrollbar': {
                                display: 'none'
                            }
                        }}
                        className="modal-wrapper">
                        {/* Header */}
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: {
                                    xs: 'column',
                                    md: 'row'
                                },
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%',
                                marginBottom: {
                                    xs: '0px',
                                    md: '30px'
                                }
                            }}>
                            <Typography
                                variant="h2"
                                sx={{
                                    fontSize: {
                                        xs: '18px',
                                        md: '20px',
                                        lg: '24px'
                                    },
                                    color: '#153862',
                                    textAlign: {
                                        xs: 'left',
                                        md: 'left'
                                    },
                                    marginBottom: {
                                        xs: '20px',
                                        md: '0'
                                    }
                                }}>
                                {translate('select_country_language')}
                            </Typography>
                            <Box
                                sx={{
                                    maxWidth: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '8px',
                                    backgroundColor: '#FFF1DC',
                                    padding: '10px 17px',
                                    borderRadius: '6px',
                                    justifyContent: 'center',
                                    '@media (min-width: 1024px)': {
                                        maxWidth: '500px'
                                    }
                                }}>
                                <Typography
                                    variant="label"
                                    sx={{
                                        textAlign: 'center',
                                        fontSize: {
                                            xs: '12px',
                                            md: '10px',
                                            lg: '12px'
                                        },
                                        color: '#153862'
                                    }}>
                                    {translate('country_info')}
                                </Typography>
                            </Box>
                        </Box>

                        {/* Tab */}

                        {isMobile ? (
                            <Box>
                                <CollapseSelectCountry value={value} setValue={setValue} handleClickChangeCountry={handleClickChangeLanguage} />
                            </Box>
                        ) : (
                            <Box>
                                <TabElement value={value}>
                                    <Box className="tab-bar">
                                        <Tabs value={value} onChange={handleChangeTab}>
                                            {StoreCountry.GetRawsCountries().map((e, index) => (
                                                <Tab
                                                    label={translate(e.continent)}
                                                    {...a11yProps(index)}
                                                    key={`tab-${index}`}
                                                    sx={{
                                                        fontSize: {
                                                            xs: '12px',
                                                            sm: '14px'
                                                        }
                                                    }}
                                                />
                                            ))}
                                        </Tabs>
                                    </Box>
                                    {StoreCountry.GetRawsCountries().map((e, index) => (
                                        <TabElement.TabPanel value={value} index={index} key={`tab-content-${index}`}>
                                            <Box
                                                sx={{
                                                    display: 'grid',
                                                    gridTemplateColumns: {
                                                        xs: '1fr',
                                                        sm: '1fr 1fr',
                                                        md: index === 1 ? '1fr 1fr' : '1fr 1fr 1fr',
                                                        lg: index === 1 ? '1fr 1fr 1fr' : '1fr 1fr 1fr 1fr',
                                                        xl: '1fr 1fr 1fr 1fr'
                                                    },
                                                    gap: '20px',
                                                    height: '100%',
                                                    minWidth: '100%'
                                                }}>
                                                {value === index &&
                                                    e.list.map((country, index) => {
                                                        const mm = country.maintenance_mode
                                                        if (mm.maintenance_mode) {
                                                            if (/developing/.test(mm.text.english) || /developing/.test(mm.text.native)) {
                                                                if (/fg.unicity.com/.test(window.location.hostname)) {
                                                                    return false
                                                                }
                                                            }
                                                        }
                                                        return (
                                                            <CountryItem country={country} key={`country-${index}`} handleClick={handleClickChangeLanguage} />
                                                        )
                                                    })}
                                            </Box>
                                        </TabElement.TabPanel>
                                    ))}
                                </TabElement>
                            </Box>
                        )}
                    </Stack>
                </Box>
            </Box>
        </ThemeProvider>
    )
})

export default SelectCountryPage
