import { observer } from 'mobx-react-lite'
import React, { useEffect, useRef, useState } from 'react'
import { Avatar, Box, Divider, Skeleton, Stack, styled, Typography } from '@mui/material'
import StoreUser from 'GlobalStores/User/StoreUser'
import StoreAuth from 'GlobalStores/User/StoreAuth'
import { useNavigate } from 'react-router-dom'
import { formatPriceDemical, useSizeWindow } from './../../../Helpers/GlobalHelpers'
import { T, useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import IconLogout from './Assets/Logout.svg'
import ImageTool from './../Settings/ProfilePictureTool'
import { ReactComponent as SpinnerIcon } from './Assets/spinner.svg'
import StoreFeelGreat from 'GlobalStores/FeelGreat/StoreFeelGreat'
import { DefaultButton } from '../Forms'
import cameraIcon from './Assets/cameraIcon.svg'
import storeCredit from 'GlobalStores/StoreCredit'
import StoreTranslate from 'GlobalStores/Cores/Translator/StoreTranslate'
import StoreCountry from 'GlobalStores/Cores/County/StoreCountry'
import { get, lowerCase, upperCase } from 'lodash'
import { autorun } from 'mobx'
import { PathHelper } from 'GlobalStores/Cores/Helpers/PathHelper'
import SessionAuth from 'GlobalStores/User/SessionAuth'

export const MenuList = [
    {
        key: 'invite',
        title: <T>invite</T>,
        path: '/invite'
    },
    {
        key: 'subscriptions',
        title: <T>subscriptions</T>,
        path: '/subscriptions'
    },
    {
        key: 'orderhistory',
        title: <T>order_history</T>,
        path: '/orderhistory'
    },
    {
        key: 'settings',
        title: <T>settings</T>,
        path: '/settings'
    }
]

const CardMenu = observer(props => {
    const [ready, setReady] = useState(false)

    useEffect(() => {
        if (StoreAuth.IsAuthorized()) {
            setReady(true)
        }
    }, [])

    return (
        <CardStyled>
            {ready && (
                <Stack justifyContent={'space-between'} sx={{ flex: 1 }}>
                    <Stack>
                        <Profile />
                        <ProductCredit />
                        <MenuLists />
                    </Stack>
                    <Stack>
                        <DividerStyled />
                        <Footer />
                    </Stack>
                </Stack>
            )}
        </CardStyled>
    )
})

const Profile = observer(() => {
    const [image, setImage] = useState(null)
    const [imageUploading, setImageUploading] = useState(false)
    const [showImageTools, setShowImageTools] = useState(false)
    const [showCameraIcon, setShowCameraIcon] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [credit, setCredit] = useState(null)
    const navigate = useNavigate()
    const inputUploadRef = useRef(null)

    const { width } = useSizeWindow()
    const PROFILE_PICTURE = StoreUser.CustomerData().ProfilePicture('original')

    useEffect(() => {
        PROFILE_PICTURE ? setShowCameraIcon(false) : setShowCameraIcon(true)
    }, [])

    useEffect(() => {
        if (StoreAuth.allowCredit) {
            storeCredit.Init({ id: StoreAuth.GetId(), token: StoreAuth.GetToken() })
        }
        setIsLoading(false)
    }, [])

    const onClickChangeProfile = () => {
        inputUploadRef.current.click()
    }

    const handleFileChange = event => {
        const fileObj = event.target.files && event.target.files[0]
        if (!fileObj) {
            return
        }
        setImage(window.URL.createObjectURL(fileObj))
        setShowImageTools(true)
        event.target.value = null
    }

    const handleOnSaveProfilePicture = async picture => {
        setImageUploading(true)
        const [res, err] = await StoreAuth.updateUserProfilePicture(picture)
        if (err) alert(err)
        if (res) {
            const userResponse = await StoreAuth.getCustomerMenu()
            let ushop = userResponse.onself.ushop
            ushop.profile.profilePicture = res
            StoreUser.setUserData(ushop)
        }
        setImageUploading(false)
    }

    const getCredit = async () => {
        try {
            if (StoreAuth.allowCredit.enable) {
                storeCredit.Init({ id: StoreAuth.GetId(), token: StoreAuth.GetToken() })
            }

            setCredit(storeCredit.GetCreditData())
            setIsLoading(false)
            // const resp = await FeelGreatAPI.GetCreditAndArBalance(StoreAuth.GetId(), StoreAuth.GetToken())

            // setCredit(resp.data)
        } catch (err) {
            setIsLoading(false)
        }
    }

    const checkStoreCredit = () => {
        // console.log('checking credit balance')
        if (StoreAuth.allowCredit) {
            const creditEnable = StoreAuth.allowCredit[storeCredit.creditType] ? StoreAuth.allowCredit[storeCredit.creditType].enable : false
            if (creditEnable) {
                if (get(StoreAuth.allowCredit[storeCredit.creditType], 'isHideZero', false) === true && storeCredit.availableBalance === 0) {
                    return false
                }
                return true
            }
        }

        return false
    }

    const checkAllowCredit = () => {
        return StoreAuth.allowCredit && (StoreAuth.allowCredit[storeCredit.creditType] ? StoreAuth.allowCredit[storeCredit.creditType].enable : false)
    }

    return (
        <Stack
            flexDirection={'row'}
            alignItems={width <= 834 ? '' : 'flex-start'}
            padding={width <= 834 ? '40px 40px 40px 40px' : '40px 30px 40px 30px'}
            flex={1}>
            {showImageTools && <ImageTool open={showImageTools} imgFile={image} onCloseModal={setShowImageTools} callback={handleOnSaveProfilePicture} />}
            <input style={{ display: 'none' }} ref={inputUploadRef} type="file" onChange={handleFileChange} />

            <Stack
                sx={{ position: 'relative', cursor: 'pointer', height: '88px', width: '88px' }}
                onClick={() => (!showCameraIcon ? setShowCameraIcon(true) : onClickChangeProfile())}>
                {PROFILE_PICTURE ? (
                    <Avatar
                        sx={{
                            bgcolor: 'transparent',
                            height: '85px',
                            width: '85px',
                            boxShadow: '0px 1px 3px rgba(68, 68, 78, 0.5)',
                            borderRadius: '20px',
                            border: '2.5px solid white',
                            opacity: imageUploading ? 0.5 : '',
                            cursor: 'pointer'
                        }}>
                        <img src={PROFILE_PICTURE} width={'100%'} height={'100%'} alt="" />
                    </Avatar>
                ) : (
                    <Avatar
                        src="/broken-image.jpg"
                        sx={{
                            height: '85px',
                            width: '85px',
                            boxShadow: '0px 1px 3px rgba(68, 68, 78, 0.5)',
                            borderRadius: '20px',
                            border: '2.5px solid white',
                            opacity: imageUploading ? 0.5 : '',
                            cursor: 'pointer',
                            position: 'relative'
                        }}
                    />
                )}
                {imageUploading && (
                    <Stack position={'absolute'} sx={{ width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
                        <IconSpinnerStyled />
                    </Stack>
                )}
                {showCameraIcon && (
                    <Stack position={'absolute'} sx={{ bottom: '-5px', right: '-5px' }}>
                        <img src={cameraIcon} alt="" />
                    </Stack>
                )}
            </Stack>

            <Stack ml={'20px'} gap={'3px'} width={width < 630 ? '63%' : '66%'}>
                {!isLoading && (
                    <>
                        <Stack>
                            <Typography
                                variant="caption"
                                fontSize={'24px'}
                                fontWeight="600"
                                color="#FFFFFF"
                                lineHeight="30px"
                                textOverflow="clip"
                                noWrap={false}>
                                {StoreUser.CustomerData().Name()}
                            </Typography>
                        </Stack>
                        <Typography variant="caption" fontSize="18px" fontWeight="400" color="#FFFFFF" lineHeight="25px">
                            {StoreAuth.GetId()}
                        </Typography>
                        {/* checkAllowCredit() */}
                        {storeCredit.loading ? (
                            <Typography variant="caption" fontSize="18px" fontWeight="400" color="#03C302" lineHeight="27px">
                                <IconSpinnerStyled style={{ height: '15px', width: '15px' }} />
                            </Typography>
                        ) : checkStoreCredit() ? (
                            <Typography variant="caption" fontSize="18px" fontWeight="400" color="#03C302" lineHeight="27px">
                                <T>credit</T>:&nbsp;{`${formatPriceDemical(storeCredit.availableBalance)} ${storeCredit.currency}`}
                            </Typography>
                        ) : null}
                    </>
                )}

                {isLoading && (
                    <Box sx={{ maxWidth: 450 }}>
                        <Skeleton animation="wave" height={50} sx={{ background: 'rgb(0 0 0 / 24%)' }} />
                        <Skeleton animation="wave" height={20} sx={{ background: 'rgb(0 0 0 / 24%)' }} />
                        <Skeleton animation="wave" height={20} sx={{ background: 'rgb(0 0 0 / 24%)' }} />
                    </Box>
                )}
            </Stack>
        </Stack>
    )
})

const ProductCredit = observer(() => {
    const { width } = useSizeWindow()
    const navigate = useNavigate()
    const translate = useTranslate()

    try {
        const description1 = StoreTranslate.UseLanguage(StoreFeelGreat.GetContent('mainBanner').description).split('<p>')[1].split('</p>')[0]
        const description2 = StoreTranslate.UseLanguage(StoreFeelGreat.GetContent('mainBanner').description).split('<p>')[2].split('</p>')[0]
        const description = `${description1} ${description2}`
        return (
            <ProductCreditStyled>
                <Stack flexDirection={'row'} gap={'12px'}>
                    <Stack>
                        <Typography
                            variant="caption"
                            sx={{
                                lineHeight: '30px',
                                color: '#003B6F',
                                fontSize: width < 414 ? '25px' : '28px',
                                fontWeight: 600,
                                fontFamily: 'Poppins, NotoSansThai',
                                letterSpacing: '0'
                            }}
                            dangerouslySetInnerHTML={{
                                __html: StoreTranslate.UseLanguage(StoreFeelGreat.GetContent('mainBanner').title)
                            }}
                        />

                        <Typography
                            mt="5px"
                            variant="caption"
                            sx={{ fontSize: width < 414 ? '12px' : '13px', fontWeight: 400, color: '#003B6F', letterSpacing: '0' }}
                            dangerouslySetInnerHTML={{
                                __html: description
                            }}
                        />

                        {/* <Typography
                            component={'div'}
                            variant="caption"
                            sx={{ 
                                fontSize: width < 414 ? '12px' : '14px', 
                                fontWeight: 400, 
                                display: 'flex', 
                                flexDirection: 'row', 
                                gap: width <= 834 ? '5px' : '', 
                                color: '#003B6F', 
                                letterSpacing: 0,
                                lineHeight: 0,
                            }}
                            dangerouslySetInnerHTML={{
                                __html: StoreFeelGreat.GetContent('mainBanner').description.english
                            }}
                        /> */}

                        <Stack mt="15px">
                            <DefaultButton
                                titleText={translate('buy_now')}
                                backgroundcolor={'#003B6F'}
                                // width={'167px'}
                                width="fit-content"
                                height={'40px'}
                                onClick={() => navigate('/products')}
                            />
                        </Stack>
                    </Stack>
                </Stack>
            </ProductCreditStyled>
        )
    } catch (error) {
        console.log('ProductCredit error', error)
        return <></>
    }
})

const MenuLists = observer(() => {
    const navigate = useNavigate()
    const baStatus = StoreUser.GetBaStatus()

    const [menuList, setMenuList] = useState([])
    const JPMenuList = [MenuList[0], MenuList[2], MenuList[1], MenuList[3]]
    const DefaultMenuList = StoreCountry.Country3() === 'JPN' ? JPMenuList : MenuList

    useEffect(() => {
        let menus = DefaultMenuList
        if (['H', 'C'].includes(baStatus)) {
            menus = menus.filter(list => list.key !== 'invite')
        }

        if (['PH', 'MA', 'TW', 'HK', 'TR', 'JO', 'ZA', 'KW'].includes(StoreCountry.Country2())) {
            menus = menus.filter(list => list.key !== 'subscriptions')
        }

        setMenuList(menus)
    }, [])

    return (
        <Stack flexDirection={'column'} marginTop="10px">
            {menuList.map(list => {
                const isActive = list.key === PathHelper.Segment(3)
                return (
                    <StackListMenuStyled
                        key={list.key}
                        sx={{ cursor: !isActive && 'pointer' }}
                        onClick={() => {
                            navigate(`/user${list.path}`)
                        }}>
                        <Typography
                            className="text-menu-list"
                            color={isActive ? '#FFFFFF' : '#A0BBDC'}
                            fontSize={'24px'}
                            fontWeight={isActive ? 600 : 300}
                            variant="label">
                            {list.title}
                        </Typography>
                    </StackListMenuStyled>
                )
            })}
        </Stack>
    )
})

const Footer = observer(() => {
    return (
        <StackFooterStyled
            sx={{ cursor: 'pointer' }}
            onClick={() => {
                StoreAuth.logout()
                SessionAuth.RemoveSession(true)
                localStorage.setItem('from-logout', '1')
                let pathRedirect = ''
                if (/localhost/.test(window.location.hostname)) {
                    pathRedirect = `//${window.location.hostname}:3000${PathHelper.BuildBasename(
                        StoreCountry.CountryLowerCase()
                    )}?lang=${StoreTranslate.CurrentLanguage()}&logout`
                } else {
                    pathRedirect = `//${window.location.hostname}${PathHelper.BuildBasename(
                        StoreCountry.CountryLowerCase()
                    )}?lang=${StoreTranslate.CurrentLanguage()}&logout`
                }
                window.location.href = pathRedirect
            }}>
            <Typography
                variant="label"
                sx={{ cursor: 'pointer', fontSize: '24px', color: '#FFBF3A', fontWeight: 300, fontFamily: 'Poppins, NotoSansThai' }}
                className="text">
                <T>sign_out</T>
            </Typography>
            <img style={{ width: '34px', height: '30px' }} src={IconLogout} alt={IconLogout} />
        </StackFooterStyled>
    )
})

const CardStyled = styled(Box)`
    width: 100%;
    flex: 1;
    // height: calc(100dvh);
    background: #003b6f;
    box-shadow: none;
    display: flex;
    flex-direction: column;
    // height: 810px;

    @media screen and (min-width: 900px) {
        border-radius: 12px;
        // height: 100%;
    }

    @media screen and (min-width: 1200px) {
        width: 430px;
        border-radius: 12px;
        /* height: 765px; */
        // height: 100%;
        max-height: auto;
        min-height: fit-content;
    }
`
const ProductCreditStyled = styled(Stack)`
    background: #ffd058;
    padding: 30px 40px;
    /* margin-top: 40px; */

    @media screen and (min-width: 900px) {
        padding: 30px 30px;
    }
`

const StackListMenuStyled = styled(Stack)`
    height: 50px;
    margin-top: 15px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 40px;
    border-left: 2px solid transparent;

    .text-menu-list {
        font-family: 'Poppins', 'NotoSansThai';
    }

    @media screen and (min-width: 900px) {
        padding: 0 30px;
        margin-top: 20px;
    }
`

const DividerStyled = styled(Divider)`
    margin: 30px 0px 0 0px;
    background-color: #99bcdf;
    opacity: 0.3;
`

const StackFooterStyled = styled(Stack)`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 30px 40px 30px 40px;
    gap: 30px;

    @media screen and (min-width: 900px) {
        flex-direction: row;
        margin: 30px;
    }
`

const IconSpinnerStyled = styled(SpinnerIcon)`
    animation-name: spin;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    width: 35px;
    height: 35px;

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`

export default CardMenu
