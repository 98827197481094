import DefaultInput from 'Components/Elements/Invite/ReferralLinks/ChangeEnrollModal/DefaultInput'
import { Box, Button, CircularProgress, Fade, InputAdornment, Modal, Stack, styled, Typography } from '@mui/material'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { T, useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import { ReactComponent as ClosePassword } from './Assets/ClosePassword.svg'
import { ReactComponent as OpenPassword } from './Assets/OpenPassword.svg'
import PopperPopup from './PopperPopup'
import storeUser from 'GlobalStores/User/StoreUser'
import storeAuth from 'GlobalStores/User/StoreAuth'

const ModalLogin = observer(({ openLoginModal, setOpenLoginModal, dataPhoneNumber }) => {
    const [error, setError] = useState()
    const [openPassword, setOpenPassword] = useState(false)
    const [loading, setLoading] = useState(false)

    const translate = useTranslate()
    const navigate = useNavigate()
    
    const localObserver = useLocalObservable(() => ({
        unicityID: '',
        password: ''
    }))
    
    const handleLogin = async() => {
        setLoading(true)
        setError(null)

        try {
            if (localObserver.unicityID !== storeAuth.id && localObserver.unicityID !== storeUser.userData.email) {
                setError('login_or_password_incorrect')
                setLoading(false)
                return
            }

            const response = await storeAuth.Login(localObserver)
            const prefix = '0'
            const concatPhoneNumberWithPrefix = dataPhoneNumber.charAt(0) === '0' ? dataPhoneNumber : `${prefix}${dataPhoneNumber}`

            if (response) {
                await storeAuth.updateUserDetails({ emailAddress: storeUser.userData.email , mobilePhone: concatPhoneNumberWithPrefix })
                setLoading(false)
                setOpenLoginModal(false)
                navigate('/user/settings/change-mobile-number-success')
            }
        } catch(e) {
            console.log('err', e.response?.data)      
            if (e.response?.data.error?.error?.status === 401) {    
                setError('login_or_password_incorrect')
            } else {
                if (e.response?.data?.error?.error?.message && e.response?.data?.error?.error?.message === 'user_status_not_allowed') {
                    setError(translate(e.response?.data?.error?.error?.message))
                }
            }

            setLoading(false)
        }
    }

    return (
        <Modal open={openLoginModal} onClose={() => setOpenLoginModal(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Fade in={openLoginModal}>
                <Box
                    sx={{
                        maxWidth: '600px',
                        width: 'auto',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        padding: '0px',
                        outline: 'none',
                        borderRadius: '12px',
                        padding: '43px 24px',
                        backgroundColor: '#ffffff',

                        '@media screen and (min-width: 600px)': {
                            padding: '44px 80px',
                        }
                    }}
                >
                    <Stack 
                        sx={{
                            gap: '40px',
                            '@media screen and (min-width: 600px)': {
                                gap: '32px',
                            }
                        }}
                    >
                        <Typography sx={{ fontSize: '24px', fontWeight: 700, color: '#003B6F' }}>
                            <T>re_authenticate_to_continue</T>
                        </Typography>
                        
                        <Stack gap={'24px'}>
                            <Stack>
                                <Stack padding={'0px 16px 8px 16px'}>
                                    <Typography 
                                        variant="label" 
                                        sx={{ 
                                            color: '#8A9BB0', 
                                            fontSize: '14px',
                                            fontFamily: 'prompt',
                                            fontWeight: 300,
                                        }}>
                                        {<T>email_or_unicity_id</T>}
                                    </Typography>
                                </Stack>
                                <DefaultInputStyled 
                                    value={localObserver.unicityID} 
                                    onChange={(e) => localObserver.unicityID = e.target.value}
                                    // haveErrorText={error ? true : false}
                                    InputProps={{
                                        'aria-autocomplete': 'none',
                                        autoComplete: 'new-password',
                                        spellCheck: 'false',
                                    }}
                                />
                            </Stack>
                            <Stack>
                                <Stack flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'} padding={'0px 16px 8px 16px'}>
                                    <Typography 
                                        variant="label" 
                                        sx={{ 
                                            color: '#8A9BB0', 
                                            fontSize: '14px',
                                            fontFamily: 'prompt',
                                            fontWeight: 300,
                                        }}>
                                        {<T>password</T>}
                                    </Typography>
                                        <Box>
                                        <PopperPopup
                                            paragraph={'password_info'}
                                        />
                                        </Box>
                                </Stack>
                                <DefaultInputStyled 
                                    value={localObserver.password} 
                                    onChange={(e) => localObserver.password = e.target.value}
                                    // haveErrorText={error ? true : false}
                                    inputProps={{
                                        type: openPassword ? 'text' : 'password',
                                        'aria-autocomplete': 'none',
                                        autoComplete: 'new-password',
                                        spellCheck: 'false',
                                    }}
                                    endAdornment={
                                        <InputAdornment position="end"
                                            sx={{
                                                position: 'absolute',
                                                right: '0px',
                                            }}
                                        >
                                            {openPassword ? (
                                                <OpenPassword width={'50px'} style={{ cursor: 'pointer' }}
                                                    onClick={() => setOpenPassword(!openPassword)}
                                                />
                                            ) : (
                                                <ClosePassword width={'50px'} style={{ cursor: 'pointer' }}
                                                    onClick={() => setOpenPassword(!openPassword)}
                                                />
                                            )}
                                        </InputAdornment>
                                    }
                                />
                            </Stack>
                        </Stack>
                    </Stack>
                    

                    {error && (
                        <Stack sx={{ background: '#FFE7EB', borderRadius: '8px', padding: '12px 16px', marginTop: '28px'  }}>
                            <Typography variant="label" sx={{ color: '#FE5D7C', textAlign: 'center', fontSize: '14px', fontWeight: 300 }}>
                                <T>{error}</T>
                            </Typography>
                        </Stack>
                    )}

                    <Stack alignItems={'center'} mt="28px">
                        <ContinueButtonStyled 
                            onClick={handleLogin} 
                            disabled={(loading === true) || (localObserver.unicityID.length === 0) || (localObserver.password.length === 0)} 
                            disableRipple
                        >
                            {loading ? <CircularProgress size={24} color={'inherit'} /> : <T>continue</T>}
                        </ContinueButtonStyled>

                        <ForgetPasswordButtonStyled
                            disableRipple
                            onClick={() => navigate('/reset-password')}
                        >
                            <T>forget_password</T>
                        </ForgetPasswordButtonStyled>
                    </Stack>
                </Box>
            </Fade>
        </Modal>
    )
})

const DefaultInputStyled = styled(DefaultInput)`
    .MuiInputBase-input {
        height: 50px;
        font-size: 16px;
        font-weight: 400;
        color: #003B6F;
        font-family: 'prompt';
        border-radius: 8px;
        padding: 15px 16px;
        
    }
    .MuiInputBase-input {
        border: ${props => props.haveErrorText ? '2px solid #FE5D7C' : '1px solid ##CCDEEF'};
    }
`

const ContinueButtonStyled = styled(Button)`
    width: 295px;
    height: 50px;
    background-color: #079ff4;
    color: #ffffff;
    border-radius: 99px;
    font-size: 20px;
    font-weight: 600;
    padding: 10px 20px;
    text-transform: capitalize;
    font-family: 'Poppins';
    font-style: normal;

    &.MuiButton-root.Mui-disabled {
        background-color: #CCDEEF;
        color: #ffffff;
    }

    &:hover {
      background-color: #079ff4;
    }

    & .MuiTouchRipple-child {
      background-color: transparent;
    }
 
    @media screen and (min-width: 430px) {
        width: 340px;
    }
`

const ForgetPasswordButtonStyled = styled(Button)`
    height: 0px;
    margin-top: 20px;
    background-color: transparent;
    color: #5A8FC3;
    font-size: 14px;
    font-weight: 300;
    font-family: 'prompt';
    text-transform: capitalize;
    text-decoration: underline;

    &:hover {
      background-color: transparent;
      text-decoration: underline;
    }

    & .MuiTouchRipple-child {
      background-color: transparent;
    }
`
export default ModalLogin