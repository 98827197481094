import axios from 'axios'
import { ServerEnv } from 'Configs/ServerEnv'
import StoreCountry from 'GlobalStores/Cores/County/StoreCountry'
import StorePaymentStep from 'GlobalStores/Cores/Elements/StorePaymentStep'
import { ObjectHelpers } from 'Helpers/ObjectHelpers'
import { get } from 'lodash'
import { makeAutoObservable, runInAction, toJS } from 'mobx'
import { FeelGreatAPI } from 'Services/FeelGreat/APIs'
import TrackingEvents from 'Services/Tracker/TrackingEvents'
import Payment from '../Payment'
import { PaymentAPIs } from '../PaymentServices/PaymentAPI'
import StoreAuth from 'GlobalStores/User/StoreAuth'
import storeCredit from 'GlobalStores/StoreCredit'
import States from 'Components/Elements/Forms/StatesModal/Country/AustraliaStates.json'
import StoreCheckout from 'GlobalStores/Checkout/StoreCheckout';

class Kuwait {
    autoAddressDisplayKeys = ['street', 'locality', 'postal_code']
    shippingMethod = 'delivery'
    autoAddressKeyToForm = {
        street: 'street_address',
        locality: 'city',
        administrative_area: 'state',
        postal_code: 'zip_code'
    }

    chainAddress = {
        isChainAddress: false,
        groupAddress: []
    }

    uuid = ''

    paymentMethod = ''

    toFormat = {
        enroll: {
            uuid: this.GetUuid(),
            fullName: '',
            address: '',
            city: '',
            state: '',
            zip: '',
            email: '',
            phone: '',
            password: ''
            // password1: '',
            // password2: ''
        },
        enrollWithReferral: {
            uuid: this.GetUuid(),
            fullName: '',
            address: '',
            city: '',
            state: '',
            zip: '',
            email: '',
            phone: '',
            password1: '',
            password2: ''
        },
        shop: {
            uuid: this.GetUuid(),
            firstname_en: '',
            lastname_en: '',
            email: '',
            phone: '',
            flat_villa: '',
            building_name: '',
            city: '',
            area: '',
            email: '',
            // shipment_options: 'delivery'
        }
    }

    formShop = {
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        password: '',
        flat_villa: '',
        building_name: '',
        city: '',
        area: '',
    }

    createAccountWithShippingAddress = {
        type: 'enroll',
        referral_id: '',
        firstname_en: '',
        lastname_en: '',
        email: '',
        phone: '',
        password: '',
        flat_villa: '',
        building_name: '',
        city: '',
        area: '',
        period: ''
    }

    ExcludedFieldsShippingIfCreateAccount = ['fullName']

    ExcludedFieldsShipping = []

    mapFormKeyToFormatKey = {
        firstname: 'firstname_en',
        lastname: 'lastname_en',
        city_selection: 'city',
        area_selection: 'area'
    }

    ArrangeCreateCheckoutForm = ['firstname', 'lastname', 'email', 'password', 'phone', 'country', 'flat_villa', 'building_name', 'city_selection', 'area_selection']

    PaymentStep = {
        payment: 0,
        createOrder: 0,
        autoship: -2,
        updateLog: 0
    }

    breadcrumbOption = [
        {
            caption: 'customise',
            link: '/products',
            paramsRequire: false
        },
        {
            caption: 'signup_shipping',
            link: '/checkout/create-account',
            paramsRequire: true,
            authCaption: 'shipping',
            authLink: '/checkout/shipping'
        },
        // {
        //     caption: 'billing',
        //     link: '/checkout/payment',
        //     paramsRequire: true
        // },
        {
            caption: 'purchase',
            link: '/checkout/payment',
            paramsRequire: true
        }
    ]

    shippingMethods = [
        {
            id: 1,
            api_value: 'delivery',
            title: 'standard_delivery',
            dict_key: '',
            for_status: [],
            options: [
                {
                    id: 1,
                    title: 'Myfatoorah',
                    dict_key: 'myfatoorah',
                    for_status: [],
                    value: 'myfatoorah',
                    descripion: {
                        title: '',
                        dict_key: ''
                    },
                    icon: 'creditcard-icon'
                },
                // {
                //     id: 2,
                //     title: 'Bank wire',
                //     dict_key: 'bankwire',
                //     for_status: [],
                //     value: 'bankwire',
                //     descripion: {
                //         title: '',
                //         dict_key: ''
                //     },
                //     icon: 'creditcard-icon'
                // }
            ]
        }
    ]

    orderSummaryConfig = {
        subTotal: {
            display: true,
            dict_key: 'subtotal'
        },
        shipping: {
            display: false,
            dict_key: 'shipping'
        },
        productCredit: {
            display: true,
            dict_key: 'product_credit',
            icon: true,
            allowToUse: []
        },
        vat: {
            display: false,
            dict_key: 'vat',
            value: 0.2 // 20%
        }
    }

    landingLayouts = [
        {
            nameComponent: 'banner',
            titleContent: 'mainBanner',
            style: {}
        },
        {
            nameComponent: 'ourApproach',
            titleContent: 'ourApproach',
            style: {
                minHeight: '0px'
            }
        },
        {
            nameComponent: 'theProducts',
            titleContent: 'theProducts',
            style: {
                backgroundColor: '#F1F6FB'
            }
        },
        {
            nameComponent: 'thePractice',
            titleContent: 'thePractice',
            style: {}
        },
        {
            nameComponent: 'howItWorks',
            titleContent: 'howItWorks',
            style: {
                backgroundColor: '#FBF7F1'
            }
        },
        {
            nameComponent: 'whatExpertsSay',
            titleContent: 'expertsSay',
            style: {}
        },
        {
            nameComponent: 'realResults',
            titleContent: 'realResults',
            style: {
                backgroundColor: '#F2F9F8'
            }
        }
    ]

    productLayouts = [
        {
            nameComponent: 'productDetailSelection',
            titleContent: 'productDetailSelection',
            style: {}
        },
        {
            nameComponent: 'theProducts',
            titleContent: 'theProducts',
            style: {}
        },
        {
            nameComponent: 'frequentlyAskedQuestions',
            titleContent: 'questions',
            style: {
                backgroundColor: '#F6F9FC'
            }
        }
    ]

    StatesList() {
        let items = []
        States.map(raw => {
            items.push({
                text: raw.abbreviation,
                value: raw.abbreviation,
                html: `<span>${raw.name}</span>`
            })
        })
        return items
    }

    AddressOptionList(key) {
        let items = []
        States.map(raw => {
            items.push({
                text: raw.abbreviation,
                value: raw.abbreviation,
                html: `<span>${raw.name}</span>`
            })
        })
        return items
    }

    MapAutoAddressToForm(form, autoAddressData) {
        const swappedValuesAsKeys = ObjectHelpers.swapKeysAndValues(this.autoAddressKeyToForm)
        return form.map(item => {
            if (swappedValuesAsKeys[item.key]) {
                if (autoAddressData.data) {
                    item.value = autoAddressData.data[swappedValuesAsKeys[item.key]]
                }
            }

            return item
        })
    }

    FormToFormat(type, form) {
        form.map(item => {
            if (this.mapFormKeyToFormatKey[item.key]) {
                if (this.toFormat[type]) {
                    runInAction(() => {
                        this.toFormat[type][this.mapFormKeyToFormatKey[item.key]] = item.value
                    })
                }
            } else {
                if (this.toFormat[type]) {
                    runInAction(() => {
                        this.toFormat[type][item.key] = item.value
                    })
                }
            }

            return item
        })
    }

    SetUuid(value) {
        this.uuid = value
    }

    GetUuid() {
        return this.uuid
    }

    GetFormat(type) {
        return this.toFormat[type]
    }

    GetPaymentStep() {
        return this.PaymentStep
    }

    GetBreadcrumbOption() {
        return this.breadcrumbOption
    }

    SetPaymentStep(key, value) {
        this.PaymentStep[key] = value
    }

    GetOrderSummaryConfig() {
        return this.orderSummaryConfig
    }

    GetLandingLayout() {
        return this.landingLayouts
    }

    GetProductLayout() {
        return this.productLayouts
    }

    // GetShippingMethod() {
    //     return this.shippingMethods
    // }

    GetShippingMethod() {
        return this.shippingMethods.find(item => item.api_value === this.shippingMethod)
    }

    GetShippingMethods() {
        return this.shippingMethods
    }

    SetPaymentMethod(value) {
        runInAction(() => {
            this.paymentMethod = value
        })
    }

    GetPaymentMethod() {
        return this.paymentMethod
    }

    SomeCustomFormSelector = (formItem) => {

        switch (formItem.key) {
            case 'city_selection':
                formItem.placeholder = ''
                formItem.type = 'text' //Edit when have city list 
                break;
            case 'area_selection':
                formItem.placeholder = ''
                formItem.type = 'text' //Edit when have area list
                break;
            default:
                break;
        }
        return formItem
    }

    /**
     *
     * @param {*} uuid
     * @param {object} detailPage { products, detailPage, isSubscribe, isEnroll, type}
     * @param {*} CreditCardEncrypted
     */
    async SubmitPayment(uuid, detailPage, CreditCardEncrypted, products) {
        try {
            const Pay = new Payment(uuid, detailPage)
            const responsePreparePayment = await Pay.PreparePaymentData()

            if (!Pay.hydra.transactions) {
                Pay.hydra.transactions = {}
            }

            Pay.hydra.transactions = {
                items: [
                    {
                        amount: 'this.terms.total',
                        type: 'IOU',
                        method: 'BankWire',
                        billToEmail: responsePreparePayment.data.data.hydra.shipToEmail,
                        billToPhone: responsePreparePayment.data.data.hydra.shipToPhone,
                        billToAddress: {
                            country: StoreCountry.Country2()
                        }
                    }
                ]
            }

            await Pay.PostToHydra()

            Pay.SendEmailV4()
            Pay.SendSms()

            await Pay.UpdateToLog()

            const responsePaymentDetail = await PaymentAPIs.GetPaymentDetail(responsePreparePayment.data.data.payment_id)
            runInAction(() => {
                StorePaymentStep.PaymentDone = true
            })

            try {
                TrackingEvents.PurchaseEvent({
                    transaction_id: responsePreparePayment.data.data.payment_id,
                    value: Pay.ushop.total_price,
                    currency: 'KWD',
                    category: Pay.ushop.shipment_purchas_option,
                    items: products
                })
            } catch (e) {
                console.error('TrackingEvents.PurchaseEvent', e)
            }
            return {
                success: true,
                payment_id: responsePreparePayment.data.data.payment_id,
                order_number: responsePaymentDetail.data.order_number
            }
        } catch (error) {
            console.error(error)
            runInAction(() => {
                StorePaymentStep.PaymentDone = true
            })

            return { success: false }
        }
    }

    async CreateAccount(uuid, accForm, shippingForm, referral, cartItems) {
        try {
            const forms = Object.assign(this.createAccountWithShippingAddress, accForm)
            const returnShippingAddress = {}

            shippingForm.map(item => {
                if (this.ExcludedFieldsShippingIfCreateAccount.includes(item.key)) {
                    return false
                }
                if (this.mapFormKeyToFormatKey[item.key]) {
                    forms[this.mapFormKeyToFormatKey[item.key]] = item.value
                    returnShippingAddress[this.mapFormKeyToFormatKey[item.key]] = item.value
                } else {
                    forms[item.key] = item.value
                    returnShippingAddress[item.key] = item.value
                }
            })

            if (referral) {
                forms.referral_id = referral
            } else {
                delete forms.referral_id
            }

            delete forms.country

            const periodResponse = await FeelGreatAPI.GetPeriod('KW')
            forms.period = periodResponse.data.entryPeriod
            forms.uuid = uuid
            forms.shipment_packs = JSON.stringify(cartItems)
            const url = `https://${ServerEnv.MemberCalls2()}.unicity.com/hot/formatV2/KWT/feelGreat/createAccount${ServerEnv.ENV === 'DEV' ? '?dev=1' : '?emailDelay=1h'
                }`
            const response = await axios.post(url, forms)

            return {
                ...response.data,
                form: forms
            }
        } catch (e) {
            console.error(e)

            return {
                success: false
            }
        }
    }

    constructor() {
        StorePaymentStep.SetPaymentSteps(this.PaymentStep)
        StoreCheckout.SetShippingMethod(this.shippingMethods[0]) // Use on MVP only.
        makeAutoObservable(this)
    }
}

export default Kuwait
